import { handleQueryResolve } from '../utils'

export default function (dateSelected, itemsPerPage, page, sortBy, sortDesc) {
  console.log(dateSelected, itemsPerPage, page, sortBy, sortDesc)
  let start = 0
  if (page === 0) {
    start = 1
  } else {
    start = (page * itemsPerPage)
  }

  return this.query(`
SELECT TOP ${itemsPerPage} START AT ${start}
--SELECT 
(SELECT COUNT(DBA.Prescription.RxNumber) as totalRx FROM DBA.Prescription WHERE (DBA.Prescription.FillDate = '${dateSelected}' OR DBA.Prescription.WB_Date = '${dateSelected}')),
  "DBA"."Prescription"."RxNumber" AS "RX NUMBER",
  "DBA"."Prescription"."FillDate" AS "RX DATE",
  "DBA"."Prescription"."Status" AS "STATUS",
  "DBA"."Prescription"."InstructionText" AS "SIG",
  "DBA"."Patient"."FirstName" AS "PATIENT FIRST NAME",
  "DBA"."Patient"."LastName" AS "PATIENT LAST NAME",
  "DBA"."Prescription"."FillQuantity" AS "RxQtyDispense",
  "DBA"."Drug"."GenericName" AS "GENERIC NAME",
  "DBA"."Drug"."DIN" AS "DIN",
  "DBA"."Drug"."TradeName" AS "BRAND NAME",
  "DBA"."Drug"."Strength" AS "STRENGTH",
  "DBA"."Prescription"."PatientId" AS "PATIENT ID",
  "DBA"."Doctor"."LastName" AS "DOCTOR LAST NAME",
  "DBA"."Doctor"."FirstName" AS "DOCTOR FIRST NAME",
  "DBA"."Prescription"."DaysSupply" AS "DAYS",
  "DBA"."Prescription"."FillQuantity" AS "QTY DISPENSE"
FROM
  (
      (
          "DBA"."Prescription"
          JOIN "DBA"."Drug" ON "DBA"."Prescription"."DrugId" = "DBA"."Drug"."Id"
      )
      JOIN "DBA"."Patient" ON "DBA"."Prescription"."PatientId" = "DBA"."Patient"."Id"
  )
  JOIN "DBA"."Doctor" ON "DBA"."Prescription"."DoctorId" = "DBA"."Doctor"."Id"
WHERE
  (
      DBA.Prescription.FillDate = '${dateSelected}'
      OR DBA.Prescription.WB_Date = '${dateSelected}'
  )
  AND (
      DBA.Prescription.Status LIKE 'COM'
      OR DBA.Prescription.Status LIKE 'AMD'
  )
  ORDER BY ${sortBy} ${sortDesc}
        `).then(handleQueryResolve)
}
